<template>
  <div class="project-builder-workflow project-scope">
    <ConfirmDialog ref="confirmDialog" />

    <CCard class="project-builder-workflow__card">
      <CCardBody>
        <CCol class="col-12 col-lg-12 col-xl-8">
          <BuilderSteps class="project-builder-workflow__steps" :step="step" />
        </CCol>

        <div v-if="loadingPage" class="project-builder-workflow__card-loading">
          <CSpinner color="primary" />
        </div>

        <template v-else>
          <CNInput
            v-model="form.projectName"
            :disabled="disabled"
            label="Project Name"
            class="project-builder-workflow__project-name"
            required
            variant="ghost"
          />

          <div class="project-builder-workflow__content">
            <h3 class="project-builder-workflow__title">Scope Workflow</h3>

            <p class="project-builder-workflow__paragraph">
              The workflows to draft and approve the scope of work can be
              updated in the
              <router-link
                v-if="canToSettings"
                :to="{ name: 'SettingScopeApproval' }"
              >
                Settings
              </router-link>
              <router-link v-else to="" @click="showError()">
                Settings
              </router-link>
              .
            </p>

            <div
              class="project-builder-workflow__workflow"
              :class="{
                'project-builder-workflow__workflow--no-options':
                  !scopesApproval.length,
              }"
            >
              <CNSelect
                v-model="form.scopeId"
                class="project-builder-workflow__select"
                :options="scopesApproval"
                :loading="loadingScopesApproval"
                :disabled="loadingScopesApproval || disabled"
                label="Select Scope Workflow"
                required
              >
                <template #nooptions>
                  <EmptyOptionsList
                    title="Set workflows"
                    description="There is no approval workflow currently available yet.
                    <br />
                    One needs to be set-up before any projects can be created.
                    <br />
                    Let us help you with this task!"
                    :button="{
                      text: 'Add workflow',
                      route: () =>
                        $router.push({ name: 'SettingScopeApproval' }),
                    }"
                  />
                </template>
              </CNSelect>
            </div>

            <div
              class="project-builder-workflow__sections"
              :class="[
                !form.scopeId && 'project-builder-workflow__sections--disabled',
              ]"
            >
              <div class="project-builder-workflow__sections-item">
                <h4 class="project-builder-workflow__heading">
                  People drafting this scope of work
                </h4>

                <p
                  v-if="form.mandatoryJobsTitlesDrafting"
                  class="project-builder-workflow__description"
                >
                  It includes: {{ form.mandatoryJobsTitlesDrafting }}
                </p>

                <DynamicDropdownsList
                  :options="optionsDrafting"
                  :items="form.fieldsDrafting"
                  :can-delete="!disabled"
                  :disabled="
                    disabledFieldsDrafting || !form.scopeId || disabled
                  "
                  @change="changePeopleWorkflow($event, 'fieldsDrafting')"
                />
              </div>

              <div class="project-builder-workflow__sections-item">
                <h4 class="project-builder-workflow__heading">
                  People approving this scope of work
                </h4>

                <p
                  v-if="form.mandatoryJobsTitlesApproving"
                  class="project-builder-workflow__description"
                >
                  It includes: {{ form.mandatoryJobsTitlesApproving }}
                </p>

                <DynamicDropdownsList
                  :options="optionsApproving"
                  :items="form.fieldsApproving"
                  :can-delete="!disabled"
                  :disabled="
                    disabledFieldsApproving || !form.scopeId || disabled
                  "
                  @change="changePeopleWorkflow($event, 'fieldsApproving')"
                />
              </div>
            </div>
          </div>

          <FixedBottomPanel
            :buttons="fixedBottomPanelButtons"
            @deleteHandler="deleteProjectHandler"
          />
        </template>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import workflowMixin from '../workflowMixin'
import { mapGetters } from 'vuex'
import { permissionError } from '@/services/Notify/Toasts'
import $p from '@/utils/constants/permissions'

export default {
  name: 'Index',
  mixins: [workflowMixin],
  async beforeRouteLeave() {
    if (this.isEditForm) {
      await this.$refs.confirmDialog
        .confirm({
          text: this.$t('messages.unsaved'),
          confirmText: 'Leave',
          cancelText: 'Cancel',
          reverse: true,
        })
        .then((response) => {
          if (response) {
            return true
          } else {
            return Promise.reject(false)
          }
        })
    } else {
      return true
    }
  },
  data() {
    return {
      form: {
        projectName: '',
        scopeId: '',
        fieldsDrafting: [],
        fieldsApproving: [],
        mandatoryJobsTitlesDrafting: '',
        mandatoryJobsTitlesApproving: '',
      },
      origin: {},

      scopesApproval: [],
      loadingScopesApproval: false,

      optionsDrafting: [],
      optionsApproving: [],

      disabledFieldsDrafting: false,
      disabledFieldsApproving: false,

      step: 2,
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'projectPermissions',
      user: 'user',
    }),
    disabled() {
      return !this.permissions.editOrCreate
    },
    canToSettings() {
      return this.user.can($p.ACCOUNT_CONFIGURATION_VIEW)
    },
    disabledSavingButtons() {
      return (
        !this.form.fieldsDrafting?.length || !this.form.fieldsApproving?.length
      )
    },
    fixedBottomPanelButtons() {
      return [
        {
          text: 'Cancel',
          disabled: this.loading || !this.isEditForm,
          outline: true,
          event: () => this.handleCancel(),
        },
        {
          text: 'Save',
          disabled: this.loading || this.disabledSavingButtons,
          outline: true,
          event: () => this.save({ workflowKey: 'scope' }),
        },
        {
          text: 'Save & next',
          disabled: this.loading || this.disabledSavingButtons,
          event: () => this.save({ workflowKey: 'scope', redirect: true }),
        },
      ]
    },
  },
  watch: {
    'form.scopeId'(scopeId) {
      if (scopeId) {
        this.fetchScopeWorkflowFields(scopeId)
      }
    },
    'form.fieldsDrafting'(fields) {
      this.setOptionsWithExcludedIds(fields, 'Drafting')
    },
    'form.fieldsApproving'(fields) {
      this.setOptionsWithExcludedIds(fields, 'Approving')
    },
  },
  methods: {
    showError() {
      this.$notify(permissionError)
    },
    fetchData() {
      const requests = [
        this.fetchProjectData(),
        this.fetchScopeApprovalWorkflowList(),
      ]

      return Promise.all(requests)
    },
    fetchProjectData() {
      this.loading = true
      return this.$http.projects
        .getProjectInfo(this.$route.params.id)
        .then(({ data: { data } }) => {
          this.form.projectName = data.name
          this.form.scopeId = data.scope_approval_workflow_id

          this.origin = this.$deepClone(this.form)

          this.step = data.step
        })
        .finally(() => (this.loading = false))
    },
    fetchScopeApprovalWorkflowList() {
      this.loadingScopesApproval = true
      this.loading = true
      return this.$http.setting
        .scopesApprovalWorkflowList({
          params: { per_page: -1 },
        })
        .then(({ data: { data } }) => {
          this.scopesApproval = data.map((scope) => ({
            value: scope.id,
            name: scope.name,
          }))
        })
        .finally(() => {
          this.loading = false
          this.loadingScopesApproval = false
        })
    },
    fetchScopeWorkflowFields(scopeId) {
      this.loading = true
      this.$http.projects
        .getScopeWorkflowFields({
          params: {
            project_id: this.$route.params.id,
            workflow_id: scopeId,
          },
        })
        .then(({ data: { data } }) => {
          const scopeApprovals = data.scope_approvals
          const scopeEditing = data.scope_editing

          this.form.mandatoryJobsTitlesApproving = data.scope_approvals_includes
          this.form.mandatoryJobsTitlesDrafting = data.scope_editing_includes

          this.form.fieldsApproving =
            this.dataAdapterWorkflowFields(scopeApprovals)
          this.form.fieldsDrafting = this.setFieldsDrafting(scopeEditing)

          if (
            this.origin.scopeId === scopeId &&
            this.form.fieldsApproving !== this.origin.fieldsApproving &&
            this.form.fieldsDrafting !== this.origin.fieldsDrafting
          ) {
            this.origin = this.$deepClone(this.form)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    setFieldsDrafting(data) {
      if (this.form.scopeId === this.origin.scopeId) {
        return this.dataAdapterWorkflowFields(data)
      } else {
        const users = data
          ?.filter((el) => el.position_name)
          .map((el) =>
            el.users.length
              ? el.users?.map((user) => ({
                  ...user,
                  position_name: el.position_name,
                  setting_id: el.setting_id,
                  mandatory: el.mandatory,
                }))
              : {
                  id: null,
                  full_name: null,
                  job_title: null,
                  position_name: el.position_name,
                  setting_id: el.setting_id,
                  mandatory: el.mandatory,
                },
          )

        return users?.flat().map((el) => ({
          settingId: el?.setting_id,
          positionName: el.position_name,
          mandatory: el.mandatory,
          value: el.id,
          name: el.id ? `${el.full_name} (${el.job_title})` : null,
        }))
      }
    },
  },
}
</script>

<style lang="scss">
@import '../style';
</style>
